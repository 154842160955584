import {Component} from "react";
import React from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Layout from "../components/Layout";
import Grid from "@material-ui/core/Grid";
import TotalSales from "../components/overview/TotalSales";
import DailyTotalSalesChart from "../components/stats/DailyTotalSalesChart";
import DailyTotalAllSalesChart from "../components/overview/DailyTotalAllSalesChart";

const styles = {
    paper: {
        width: '1',
    },
};

class Overview extends Component {

    render(){
        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item md={4} sm={6} xs={12}>
                        <Grid container direction={"column"} spacing={1}>
                            <Grid item>
                                <TotalSales/>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid item md={8} xs={12}>
                       <DailyTotalAllSalesChart />
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

function mapStateToProps(state){
    return {

    };
}

export default connect(mapStateToProps)(withStyles(styles)(Overview));