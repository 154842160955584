import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/styles/withStyles";
import {connect} from "react-redux";
import LoadingScreen from "../LoadingScreen";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Bar} from "react-chartjs-2";
import {EventAvailableOutlined} from "@material-ui/icons";
import moment from "moment";

const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
});

const chartColors = [{color: '#33ccff', borderColor: '#0099ff'}, {color: '#00cc00', borderColor: '#339933'}, {color: '#ff3399', borderColor: '#cc0099'}]

class DailyTotalSalesChart extends Component {

    constructor(props) {
        super(props);

        this.state = {type: 0}
    }

    render() {
        if (this.props.stats.isFetching) {
            return (
                <Paper className={this.props.classes.paper}>
                    <Grid container style={{justifyContent: "flex-start", alignItems: "center"}}>
                        <Grid>
                            <Typography variant={"h6"} className={this.props.classes.header}>
                                Denní obraty
                            </Typography>
                        </Grid>
                    </Grid>
                    <LoadingScreen reason={"Zpracovávám data..."}/>
                </Paper>
            );
        }

        let sales = {};

        let labels = [];
        let cashData = {};

        for(const key of Object.entries(this.props.stats)) {
            if (key[0] === 'isFetching') continue;
            key[1].total.forEach(t => {
                let d = new Date(t.date);
                let date = d.getDate() + ". " + (d.getMonth() + 1) + ". " + d.getFullYear();

                if (sales[date] === undefined) {
                    sales[date] = {};
                }
                if(sales[date][key[0]] === undefined){
                    sales[date][key[0]] = 0;
                }
                sales[date][key[0]] += t.amount;
            });
        }

        let dataSets = [];
        
        for(let dayKey in sales){
            if(sales.hasOwnProperty(dayKey)){
                labels = [...labels, dayKey];
            }
        }
        
        labels = labels.sort((a, b) => {
            let dateA = moment(a, 'D. M. YYYY');
            let dateB = moment(b, 'D. M. YYYY');
            return dateA.valueOf() - dateB.valueOf();
        });
        
        console.log(labels);
        
        for (let i = 0; i < labels.length; i++){
            let dayKey = labels[i];
            for (let eventId in this.props.events.data) {
                const event = this.props.events.data[eventId];
                if(cashData[event.id] === undefined){
                    cashData[event.id] = [];
                }
                cashData[event.id] = [...cashData[event.id], {x: dayKey, y: sales[dayKey][event.id] !== undefined ? sales[dayKey][event.id] : 0}];
            }
        }
        
        var place = 0;
        for (let eventId in this.props.events.data) {
            const event = this.props.events.data[eventId];
            dataSets.push({
                label: event.name,
                backgroundColor: chartColors[place % chartColors.length]['color'],
                borderColor: chartColors[place % chartColors.length]['borderColor'],
                borderWidth: 1,
                data: cashData[event.id]
            });
            place++;
        }

        const data = {
            labels: labels,
            datasets: dataSets
        };

        return (
            <Paper className={this.props.classes.paper}>
                <Grid container style={{justifyContent: "flex-start", alignItems: "center"}}>
                    <Grid>
                        <Typography variant={"h6"} className={this.props.classes.header}>
                            Denní obraty v areálech
                        </Typography>
                    </Grid>
                </Grid>

                <Bar
                    data={data}
                    options={{
                        maintainAspectRatio: true, scales: {
                            yAxes: [{
                                stacked: true,
                            }],
                            xAxes: [{
                                stacked: true,
                            }],
                        }
                    }}
                    
                />

            </Paper>
        );
    }
}

function mapStateToProps(state) {
    const stats = state.statsList;
    const events = state.eventList;
    return {
        stats,
        events
    };
}

export default connect(mapStateToProps)(withStyles(styles)(DailyTotalSalesChart))