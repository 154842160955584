import React, {Component} from "react";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {addProduct, editProduct} from "../../actions/productsActions";

const styles = theme => ({
    modalGrid: {
        padding: theme.spacing(3, 2),
    },
    selectMenu: {
        width: '200px',
    },
});

class AddProductModal extends Component {

    constructor(props) {
        super(props);

        this.state = {picture: props.product.picture, name: props.product.name, category: props.category.name, price: props.product.price[0].amount, costs: props.product.cost, mystery: props.product.mystery ? 1 : 0, insideMystery: props.product.insideMystery}
    }

    render() {
        return (
            <Grid container direction={"column"} className={this.props.classes.modalGrid}>
                <Typography variant={"h4"}>
                    Upravit produkt
                </Typography>
                <Divider/>
                <TextField style={{margin: '10px'}}
                           id="outlined-select-category"
                           select
                           label="Kategorie"
                           value={this.state.category}
                           onChange={e => this.handleChange(e)}
                           name={"category"}
                           SelectProps={{
                               native: true,
                               MenuProps: {
                                   className: this.props.classes.selectMenu,
                               },
                           }}
                           margin="normal"
                           variant="outlined"
                >
                    {this.props.categories.map(option => (
                        <option key={option.id} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
                <TextField style={{margin: '10px'}}
                           id="outlined-name"
                           label="Jméno produktu"
                           value={this.state.name}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="name"
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-picture"
                           label="Náhledový obrázek"
                           value={this.state.picture}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="picture"
                           type="text"
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-price"
                           label="Cena"
                           value={this.state.price}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="price"
                           type="number"
                           inputProps={{ step: 0.01 }}
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-price"
                           label="Náklady"
                           value={this.state.costs}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="costs"
                           type="number"
                           inputProps={{ step: 0.01 }}
                />
                <TextField style={{margin: '10px'}}
                           id="outlined-select-currency"
                           select
                           label="Typ produktu"
                           value={this.state.mystery}
                           onChange={e => this.handleChange(e)}
                           name={"mystery"}
                           SelectProps={{
                               native: true,
                               MenuProps: {
                                   className: this.props.classes.selectMenu,
                               },
                           }}
                           margin="normal"
                           variant="outlined"
                >
                    <option key="mystery" value={1}>
                        Mystery box
                    </option>
                    <option key="product" value={0}>
                        Normální produkt
                    </option>
                </TextField>
                <TextField style={{margin: '10px'}}
                           id="outlined-price"
                           label="Počet v mystery boxu"
                           value={this.state.insideMystery}
                           onChange={e => this.handleChange(e)}
                           margin="normal"
                           variant="outlined"
                           name="insideMystery"
                           type="number"
                           inputProps={{ step: 1 }}
                />
                <Button type={"submit"} id={"modalButton"} variant="contained" size="medium" color="secondary" onClick={e => this.save(e)}>
                    Uložit změny
                </Button>
            </Grid>
        );
    }

    save = (e) => {
        e.preventDefault();

        let categoryId = 0;
        this.props.categories.forEach(c => {
            if (c.name === this.state.category) {
                categoryId = c.id;
            }
        });

        console.log({
            category: categoryId,
            name: this.state.name,
            picture: this.state.picture,
            price: this.state.price,
            costs: this.state.costs,
            mystery: this.state.mystery,
            insideMystery: this.state.insideMystery
        });

        if (categoryId !== this.props.product.categoryId || this.state.name !== this.props.product.name || this.state.picture !== this.props.product.picture || this.state.price !== this.props.product.price[0].amount|| this.state.costs !== this.props.product.cost || this.state.mystery !== this.props.product.mystery || this.state.insideMystery !== this.props.product.insideMystery) {
            this.props.dispatch(editProduct(this.props.product.id, categoryId, this.state.name, this.state.picture, this.state.price, this.state.costs, this.state.mystery, this.state.insideMystery));
        }

        this.props.handleClose();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

}


export default connect()(withStyles(styles)(AddProductModal));