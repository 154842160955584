import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/styles/withStyles";
import {connect} from "react-redux";
import LoadingScreen from "../LoadingScreen";
import Typography from "@material-ui/core/Typography";
import {Divider} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Bar, Doughnut, Pie} from "react-chartjs-2";
import {fetchProducts} from "../../actions/fetchProducts";
import {fetchCategories} from "../../actions/fetchCategories";
import {fetchEventsStats} from "../../actions/fetchEventsStats";
import {fetchEvents} from "../../actions/eventsActions";

const styles = theme => ({
    paper: {
        width: '1',
        padding: theme.spacing(3, 2),
    },
    header: {
        marginBottom: theme.spacing(0.5),
    },
    total: {
        textAlign: "center",
        padding: theme.spacing(1),
    },
});

const types = [
    {
        id: 0,
        limit: 0,
        display: 'Dnes',
    },
    {
        id: 1,
        limit: 6,
        display: 'Týden',
    },
    {
        id: 2,
        limit: 99999999,
        display: 'Celkově',
    }
];

class EventTotalSales extends Component {

    constructor(props) {
        super(props);

        this.state = {type: 0}
    }

    componentDidMount() {
        this.props.dispatch(fetchEvents());
        this.props.dispatch(fetchCategories());
        this.props.dispatch(fetchProducts());
        this.props.dispatch(fetchEventsStats());
    }

    render() {
        if (this.props.stats.isFetching || this.props.products.isFetching || this.props.categories.isFetching || this.props.events.isFetching ) {
            return (
                <Paper className={this.props.classes.paper}>
                    <Grid container style={{justifyContent: "space-between", alignItems: "center"}}>
                        <Grid>
                            <Typography variant={"h6"} className={this.props.classes.header}>
                                Celkový obrat
                            </Typography>
                        </Grid>

                        <Grid>
                            <TextField style={{margin: '10px'}}
                                       id="standart-select-type"
                                       select
                                       value={this.state.type.display}
                                       onChange={e => this.handleChange(e)}
                                       name={"type"}
                                       SelectProps={{
                                           native: true,
                                           MenuProps: {
                                               className: this.props.classes.selectMenu,
                                           },
                                       }}
                                       margin="normal"
                            >
                                {types.map(option => (
                                    <option key={option.id} value={option.id}>
                                        {option.display}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <LoadingScreen reason={"Zpracovávám data..."}/>
                </Paper>
            );
        }

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let amount = 0;
        for(const key of Object.entries(this.props.stats)){
            if(key[0] == 'isFetching') continue;
            key[1].total.forEach(t => {
                if (dayDiff(today, new Date(t.date)) <= types[this.state.type].limit) {
                    amount = amount + t.amount;
                }
            }) 
        }

        console.log(this.props.stats);
        console.log(amount);
        let labels = [];
        let values = [];
        let colors = [];

        this.props.categories.data.forEach(c => {
            labels = [...labels, c.name];

            let amount = 0;

            for(const key of Object.entries(this.props.stats)){
                if(key[0] === 'isFetching') continue;
                key[1].sold.forEach(s => {
                    if (dayDiff(today, new Date(s.date)) <= types[this.state.type].limit) {
                        s.records.forEach(r => {
                            if (this.getCategoryId(r.productId) === c.id) {
                                amount += r.price;
                            }
                        });
                    }
                });
            }

            values = [...values, amount];
            colors = [...colors, c.color]
        });

        //Chart
        const data = {
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: colors,
                }
            ]
        };

        return (
            <Paper className={this.props.classes.paper}>
                <Grid container style={{justifyContent: "space-between", alignItems: "center"}}>
                    <Grid item>
                        <Typography variant={"h6"} className={this.props.classes.header}>
                            Celkový obrat
                        </Typography>
                    </Grid>

                    <Grid item>
                        <TextField style={{margin: '10px'}}
                                   id="standart-select-type"
                                   select
                                   value={this.state.type.display}
                                   onChange={e => this.handleChange(e)}
                                   name={"type"}
                                   SelectProps={{
                                       native: true,
                                       MenuProps: {
                                           className: this.props.classes.selectMenu,
                                       },
                                   }}
                                   margin="normal"
                        >
                            {types.map(option => (
                                <option key={option.id} value={option.id}>
                                    {option.display}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>

                <Divider/>
                <Typography variant={"h2"} component={"div"} className={this.props.classes.total}>
                    {formatNumber(amount)} Kč
                </Typography>
                <Doughnut
                    data={data}
                />
            </Paper>
        );
    }

    handleChange = (e) => {
        this.setState({type: e.target.value})
    }

    getCategoryId = (productId) => {
        return this.props.products.data.filter(p => p.id === productId)[0].categoryId;
    }
}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

function dayDiff(d1, d2) {
    return Math.round(Math.abs(d1 - d2) / 86400000);
}

function mapStateToProps(state) {
    const stats = state.statsList;
    const products = state.productList;
    const categories = state.categoryList;
    const events = state.eventList;
    return {
        stats,
        products,
        categories,
        events
    };
}

export default connect(mapStateToProps)(withStyles(styles)(EventTotalSales))