export default function productListReducer(productList = {
    data: [],
    isFetching: true
}, action) {
    if (action.type === 'START_FETCHING_PRODUCTS') {
        return Object.assign({}, productList, {
            isFetching: true,
            data: []
        })
    }
    if (action.type === 'FETCH_PRODUCTS') {
        return Object.assign({}, productList, {
            isFetching: false,
            data: action.payload.products
        })
    }
    if (action.type === 'ADD_PRODUCT') {
        return Object.assign({}, productList, {
            data: [...productList.data, action.payload]
        })
    }
    if (action.type === 'EDIT_PRODUCT') {
        return Object.assign({}, productList, {
            data: [...productList.data.filter((value) => value.id !== action.payload.id), action.payload]
        })
    }
    if (action.type === 'DELETE_PRODUCT') {
        return Object.assign({}, productList, {
            data: productList.data.filter((value) => value.id !== action.payload.productId)
        })

    }
    return productList;
}