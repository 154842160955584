import React from 'react';
import Layout from "../components/Layout";
import CartProduct from "../components/CartProduct";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PayWithCash from "../images/cash-in-hand.png";
import PayWithCard from "../images/card.png";
import {fetchCartProducts} from "../actions/fetchCart";
import {connect} from "react-redux";
import {checkoutCart} from "../actions/cartActions";



class Cart extends React.Component {

    classes = makeStyles(theme => ({
        grid: {
            display: 'flex',
        },
    }));

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(fetchCartProducts());
    }

    render() {
        return (
            <Layout>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={8} md={6}>
                                <Paper>
                                    <Grid container alignItems="center">
                                        <Grid item xs={4} style={{textAlign: 'center'}}>
                                            <ShoppingCartIcon fontSize="inherit" color="secondary"
                                                              style={{fontSize: '80px', margin: '10px'}}/>
                                        </Grid>
                                        <Grid item xs={8} style={{textAlign: 'center'}}>
                                            <Typography variant="h3">
                                                {this.props.price} Kč
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Divider style={{marginBottom: '3px', marginTop: '3px'}}/>
                        <Paper>
                            {this.list(this.props.isFetching)}
                        </Paper>
                    </Grid>
                    <Grid item container xs={12} md={4} spacing={1} justify={"center"} alignItems={"center"}>
                        <Grid item xs={6} md={10}>
                            <Paper>
                                <img src={PayWithCash} style={{maxHeight: '100%', maxWidth: '100%'}} onClick={() => {
                                    this.props.dispatch(checkoutCart("CASH"))
                                }}/>
                            </Paper>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Paper>
                                <img src={PayWithCard} style={{maxHeight: '100%', maxWidth: '100%'}}
                                     onClick={this.payByCreditCard}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Layout>
        );
    }

    list(loading) {
        if (loading === false) {
            return (
                <Grid container className="productList">
                    {
                        this.props.products.map(product => {
                            return <CartProduct id={product.id}
                                                productId={product.product.id} name={product.product.name}
                                                picture={product.product.picture} price={product.product.price}
                                                amount={product.amount}/>
                        })
                    }
                </Grid>
            );
        } else {
            return (<div style={{textAlign: 'center', marginTop: '40px'}}>
                <div className="spinner-grow text-primary" role="status" style={{width: '3rem', height: '3rem'}}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>);
        }
    }

    payByCreditCard = () => {
        if (getMobileOperatingSystem() === 'iOS') {
            this.props.dispatch(checkoutCart("CREDIT_CARD_IOS"))
        }
        if (getMobileOperatingSystem() === 'Android') {
            this.props.dispatch(checkoutCart("CREDIT_CARD_ANDROID"))
        }
        this.props.dispatch(checkoutCart("CREDIT_CARD"))
    }
}

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}

function mapStateToProps(state) {
    let products = state.cart.data;
    let isFetching = state.cart.isFetching;
    let price = state.cart.price;
    return {
        products,
        isFetching,
        price
    }
}

export default connect(mapStateToProps)(Cart);

