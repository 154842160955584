import axios from "axios";

export function addProduct(category, name, image, price, costs, mystery, insideMystery) {
    return async function (dispatch, state) {
        axios('/api/products/add', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                category: category,
                productName: name,
                productImage: image,
                price: price,
                cost: costs,
                mystery: mystery,
                insideMystery: insideMystery
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data.result === 'SUCCESS') {
                    dispatch({type: 'ADD_PRODUCT', payload: res.data.product});
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export function editProduct(productId, category, name, image, price, costs, mystery, insideMystery) {
    return async function (dispatch, state) {
        axios('/api/products/edit', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: JSON.stringify({
                productId: productId,
                category: category,
                productName: name,
                productImage: image,
                price: price,
                cost: costs,
                mystery: mystery,
                insideMystery: insideMystery
            })
        })
            .then(res => {
                console.log(res.data);
                if (res.data === 'SUCCESS') {
                    dispatch({
                        type: 'EDIT_PRODUCT', payload: {
                            id: productId,
                            categoryId: category,
                            name: name,
                            picture: image,
                            price: [
                                {
                                    currency: "CZK",
                                    amount: price
                                }
                            ],
                            cost: costs,
                            mystery: mystery == 1,
                            insideMystery: insideMystery
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}